import axios from 'axios';
import React, { useState } from 'react';
import { isMobile } from 'react-device-detect';
import { useNavigate } from 'react-router';

import WeboardLogo from '../assets/WebordLogo.png';
import WeboardLogoColored from '../assets/WebordLogoColored.png';

function ForgotPassword() {
    const [email, setEmail] = useState('');

    const navigate = useNavigate();

    function sendForgotPassword() {
        axios
            .post(
                process.env.REACT_APP_API_URL + '/user/sendForgotPassword',
                JSON.stringify({
                    'email': email,
                }),
                {
                    'headers': {
                        'content-type': 'application/json',
                    },
                }
            )
            .then((res) => {
                if (res.status === 200) {
                }
            });
    }

    return (
        <div className="max-w-md m-8">
            <div className="w-full py-4 flex items-center justify-center">
                <img
                    src={isMobile ? WeboardLogo : WeboardLogoColored}
                    width="75%"
                    className="mb-16"
                    alt="Logo"
                />
            </div>
            <input
                className="w-full mb-2 p-2 bg-transparent focus:outline-none focus:border-accent outline-none border-b transition-all"
                placeholder="hugo@musterhase.de"
                type="email"
                required
                value={email}
                onChange={(event) => setEmail(event.target.value)}
            />
            <div className="flex mt-8 justify-between">
                <input
                    type="submit"
                    value="Zurück"
                    onClick={() => navigate('/')}
                    className="bg-transparent py-2 font-light rounded-md text-accent hover:opacity-90 active:opacity-70 cursor-pointer"
                />
                <input
                    type="submit"
                    value="Passwort zurücksetzen"
                    onClick={() => sendForgotPassword()}
                    className="bg-accent px-4 py-2 font-semibold rounded-md text-white hover:opacity-90 active:opacity-70 cursor-pointer"
                />
            </div>
        </div>
    );
}

export default ForgotPassword;
