import axios from 'axios';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectToken } from '../lib/stores/userStore';
import { setSelectedWorkspace } from '../lib/stores/workspaceStore';

function CreateWorkspace(props) {
    const [workspaceName, setWorkspaceName] = useState('');
    const token = useSelector(selectToken);

    const dispatch = useDispatch();

    function createWorkspace() {
        if (workspaceName !== '') {
            axios
                .post(
                    process.env.REACT_APP_API_URL + '/workspace/addWorkspace',
                    {
                        'name': workspaceName,
                    },
                    {
                        'headers': {
                            'content-type': 'application/json',
                            'auth-token': token,
                        },
                    }
                )
                .then((res) => {
                    if (res.status === 200) {
                        dispatch(setSelectedWorkspace(res.data));
                        props.onClose();
                    }
                });
        }
    }
    return (
        <div>
            <input
                value={workspaceName}
                onChange={(event) => setWorkspaceName(event.target.value)}
                type="text"
            />
            <input type="submit" onClick={() => createWorkspace()} />
        </div>
    );
}

export default CreateWorkspace;
