import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    allWorkspaces: null,
    selectedWorkspace: null,
    selectedWorkspaceInfo: null,
};

export const workspaceSlice = createSlice({
    name: 'workspace',
    initialState,
    reducers: {
        setAllWorkspaces: (state, action) => {
            if (action.payload) {
                state.allWorkspaces = action.payload;
            }
        },
        setSelectedWorkspace: (state, action) => {
            if (action.payload) {
                state.selectedWorkspace = action.payload;
            }
        },
        setSelectedWorkspaceInfo: (state, action) => {
            if (action.payload) {
                state.selectedWorkspaceInfo = action.payload;
            }
        },
    },
});

export const {
    setAllWorkspaces,
    setSelectedWorkspace,
    setSelectedWorkspaceInfo,
} = workspaceSlice.actions;

export const selectAllWorkspaces = (state) => state.workspace.allWorkspaces;
export const selectSelectedWorkspace = (state) =>
    state.workspace.selectedWorkspace;
export const selectSelectedWorkspaceInfo = (state) =>
    state.workspace.selectedWorkspaceInfo;

export default workspaceSlice.reducer;
