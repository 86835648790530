import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import Modal from '../../../lib/Modal';

import { selectAllStaff, setAllStaff } from '../../../lib/stores/staffStore';
import {
    selectToken,
    selectUserData,
    selectWorkspaceUserInfo,
} from '../../../lib/stores/userStore';
import { selectSelectedWorkspace } from '../../../lib/stores/workspaceStore';

function StaffDashboard() {
    const navigate = useNavigate();

    const allStaff = useSelector(selectAllStaff);
    const userInfo = useSelector(selectUserData);
    const selectedWorkspace = useSelector(selectSelectedWorkspace);
    const token = useSelector(selectToken);
    const workspaceUserInfo = useSelector(selectWorkspaceUserInfo).data;
    const dispatch = useDispatch();

    const [modalOpen, setModalOpen] = useState(false);
    const [modalScreen, setModalScreen] = useState('');
    const [modalData, setModalData] = useState({});

    useEffect(() => {
        axios
            .get(
                process.env.REACT_APP_API_URL +
                    '/staff/getAllStaff/' +
                    selectedWorkspace,
                {
                    'headers': {
                        'content-type': 'application/json',
                        'auth-token': token,
                    },
                }
            )
            .then((res) => {
                if (res.status === 200) {
                    dispatch(setAllStaff(res.data));
                }
            });
    }, [selectedWorkspace, modalOpen]);

    function openModal(Screen, data = {}) {
        setModalOpen(true);
        setModalData(data);

        setModalScreen(Screen);
    }

    function sendInvitation(email) {
        axios
            .post(
                process.env.REACT_APP_API_URL + '/staff/inviteStaff',
                {
                    'email': email,
                    'workspaceID': selectedWorkspace,
                },
                {
                    'headers': {
                        'content-type': 'application/json',
                        'auth-token': token,
                    },
                }
            )
            .then((res) => {
                if (res.status === 200) {
                }
            });
    }

    return (
        <div className="w-full h-full">
            <Modal
                element={modalScreen}
                shown={modalOpen}
                data={modalData}
                onClose={() => setModalOpen(!modalOpen)}
            />
            <div className="w-full flex justify-between">
                <div className="text-4xl text-gray-600">Mitarbeiter</div>
                <div className="flex">
                    <div
                        className={
                            workspaceUserInfo.owner ||
                            workspaceUserInfo.permissions?.inviteUser
                                ? 'bg-accent text-white px-3 py-3 rounded-xl cursor-pointer flex items-center hover:bg-accentDark active:opacity-60'
                                : 'bg-gray-500 text-white px-3 py-3 rounded-xl cursor-pointer flex items-center'
                        }
                        onClick={
                            workspaceUserInfo.owner ||
                            workspaceUserInfo.permissions?.inviteUser
                                ? () => openModal('inviteStaff')
                                : () => {}
                        }
                    >
                        <span className="material-icons mr-3">person_add</span>
                        Mitarbeiter einladen
                    </div>
                </div>
            </div>

            <div className="w-full rounded-xl bg-white shadow-xl mt-10 overflow-hidden">
                <div className="text-xl text-gray-800 font-bold pb-5 pt-6 px-12">
                    Mitarbeiter:
                </div>
                {allStaff.length !== 0 ? (
                    allStaff.map(({ data, id, extended }) => (
                        <div
                            key={id}
                            className="border-t border-gray-200 py-4 pl-12 pr-6 flex justify-between transition-all hover:bg-gray-100 cursor-pointer"
                        >
                            <div className="w-full flex flex-row items-center">
                                {data.status === 'invited' ? (
                                    <div
                                        className={
                                            'bg-purple-500 w-2 h-2 rounded-full mr-3'
                                        }
                                    />
                                ) : (
                                    <div
                                        className={
                                            (data.online
                                                ? 'bg-green-500'
                                                : 'bg-red-500') +
                                            ' w-2 h-2 rounded-full mr-3'
                                        }
                                    />
                                )}
                                {data.owner ? (
                                    <span className="material-icons mr-4">
                                        verified
                                    </span>
                                ) : (
                                    <></>
                                )}
                                <div className="w-full flex flex-col justify-center ">
                                    <div
                                        className={
                                            'w-full flex items-center justify-between text-lg ' +
                                            (data.status === 'invited'
                                                ? 'font-light text-gray-500'
                                                : 'font-normal ')
                                        }
                                    >
                                        {data.nickname || extended.email}
                                        {data.status === 'invited' ? (
                                            <div className="font-light text-gray-500 text-sm flex items-center">
                                                Eingeladen.&nbsp;&nbsp;&nbsp;
                                                <a
                                                    onClick={(e) => {
                                                        if (
                                                            e &&
                                                            e.stopPropagation
                                                        )
                                                            e.stopPropagation();
                                                        sendInvitation(
                                                            extended.email
                                                        );
                                                    }}
                                                    className="text-accent hover:text-accentDark transition-all material-icons"
                                                >
                                                    autorenew
                                                </a>
                                            </div>
                                        ) : (
                                            ''
                                        )}
                                    </div>
                                    <div></div>
                                </div>
                            </div>

                            <div className="flex justify-center items-center">
                                {extended.email === userInfo.email ? (
                                    <span
                                        className="material-icons text-primary rounded-full h-10 w-10 flex justify-center items-center mr-8 cursor-pointer transition-all hover:bg-gray-200"
                                        onClick={() =>
                                            openModal('editStaff', {})
                                        }
                                    >
                                        edit
                                    </span>
                                ) : (
                                    <></>
                                )}
                            </div>
                        </div>
                    ))
                ) : (
                    <div className="border-t border-gray-200 py-4 px-12 flex justify-between">
                        <div className="flex justify-center items-center">
                            <div className="font-normal text-lg mr-4">
                                Es ist ein Fehler beim laden der Mitarbeiter
                                aufgetreten!
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
}

export default StaffDashboard;
