import React from 'react';
import { Route, Routes } from 'react-router';
import Login from './login';
import loginIllustration from '../assets/loginIllustration.png';
import { BrowserView, MobileView } from 'react-device-detect';
import ForgotPassword from './forgotPassword';
import SignUp from './signup';
import AcceptInvite from './acceptInvite';
import NotFound from './appScreens/404';

function LoginContainer() {
    return (
        <div className="w-full h-full flex bg-background">
            <BrowserView className="w-full h-full flex">
                <div className="w-1/2 h-full shadow-2xl rounded-r-6xl bg-primary flex justify-center items-center">
                    <img src={loginIllustration} className="w-1/2" />
                </div>
                <div className="w-1/2 h-full flex justify-center items-center">
                    <Routes>
                        <Route path="/" element={<Login />} />
                        <Route
                            path="/forgotPassword"
                            element={<ForgotPassword />}
                        />
                        <Route path="/signup" element={<SignUp />} />
                        <Route path="/invite" element={<AcceptInvite />} />
                        <Route path="/*" element={<NotFound />} />
                    </Routes>
                </div>
            </BrowserView>
            <MobileView className="w-full h-full bg-mobileLoginBG bg-cover">
                <div className="h-1/6" />
                <div className="h-5/6 w-full flex justify-center items-center">
                    <Routes>
                        <Route path="/" element={<Login />} />
                        <Route
                            path="/forgotPassword"
                            element={<ForgotPassword />}
                        />
                        <Route path="/signup" element={<SignUp />} />
                        <Route path="/invite" element={<AcceptInvite />} />
                        <Route path="/*" element={<NotFound />} />
                    </Routes>
                </div>
            </MobileView>
        </div>
    );
}

export default LoginContainer;
