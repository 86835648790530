import { configureStore } from '@reduxjs/toolkit';
import workspaceReducer from './stores/workspaceStore';
import userReducer from './stores/userStore';
import projectReducer from './stores/projectStore';
import staffReducer from './stores/staffStore';

export const store = configureStore({
    reducer: {
        user: userReducer,
        workspace: workspaceReducer,
        project: projectReducer,
        staff: staffReducer,
    },
});
