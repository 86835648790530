import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Route, Routes, useNavigate } from 'react-router';
import { selectToken, selectUserData } from './lib/stores/userStore';
import ToastNotificationContainer from './lib/toast-notification/toastNotification';
import AppContainer from './screen/appContainer';
import NotFound from './screen/appScreens/404';
import Account from './screen/appScreens/account';
import Cards from './screen/appScreens/cards/cards';
import CustomerContainer from './screen/appScreens/customer/customerContainer';
import CustomerInfo from './screen/appScreens/customer/customerInfo';
import CustomerOverview from './screen/appScreens/customer/customerOverview';
import Dashboard from './screen/appScreens/dashboard';
import ProjectContainer from './screen/appScreens/projects/projectContainer';
import ProjectDashboard from './screen/appScreens/projects/projectDashboard';
import Settings from './screen/appScreens/settings';
import StaffContainer from './screen/appScreens/staff/staffContainer';
import StaffDashboard from './screen/appScreens/staff/staffDashboard';
import YourDay from './screen/appScreens/yourDay';
import WizardContainer from './screen/firstSetup/wizardContainer';
import LoginContainer from './screen/loginContainer';

function App() {
    const token = useSelector(selectToken);
    const userData = useSelector(selectUserData);
    const navigate = useNavigate();

    require('dotenv').config();

    useEffect(() => {
        if (userData) {
            if (userData.firstVisit) {
                navigate('/wizard');
            }
        }
    }, [userData]);

    return (
        <div className="w-full h-full bg-background">
            <Routes>
                <Route path="/wizard" element={<WizardContainer />} />

                <Route
                    path="/"
                    element={token ? <AppContainer /> : <LoginContainer />}
                >
                    <Route path="" element={<Dashboard />} />
                    <Route path="/your-day" element={<YourDay />} />
                    <Route path="/quick-task" element={<></>} />
                    <Route path="/projects" element={<ProjectContainer />}>
                        <Route path="" element={<ProjectDashboard />} />
                    </Route>
                    <Route path="/customer" element={<CustomerContainer />}>
                        <Route path="" element={<CustomerOverview />} />
                        <Route path=":id" element={<CustomerInfo />} />
                    </Route>
                    <Route path="/staff" element={<StaffContainer />}>
                        <Route path="" element={<StaffDashboard />} />
                    </Route>
                    <Route path="/cards" element={<Cards />} />
                    <Route path="/account" element={<Account />} />
                    <Route path="/settings" element={<Settings />} />
                </Route>

                <Route path="*" element={<NotFound />} />
            </Routes>
            <ToastNotificationContainer toastList={[]} />
        </div>
    );
}

export default App;
