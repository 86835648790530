import axios from 'axios';
import { createHash } from 'crypto';
import React, { useState } from 'react';
import { isMobile } from 'react-device-detect';
import { useNavigate } from 'react-router';
import WeboardLogo from '../assets/WebordLogo.png';
import WeboardLogoColored from '../assets/WebordLogoColored.png';

function SignUp() {
    const navigate = useNavigate();

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    function logUserIn() {
        const hashedPassword = createHash('sha256')
            .update(password)
            .digest('hex');

        axios
            .post(
                process.env.REACT_APP_API_URL + '/auth/signup',
                {
                    'email': email,
                    'password': hashedPassword,
                    'permissions': ['permission.company.editCompany'],
                },
                {
                    'headers': {
                        'content-type': 'application/json',
                    },
                }
            )
            .then((res) => {
                if (res.status === 200) {
                    navigate('/');
                }
            });
    }

    return (
        <div className="max-w-md m-8">
            <div className="w-full flex flex-col items-center justify-center mb-12">
                <img
                    src={isMobile ? WeboardLogo : WeboardLogoColored}
                    width="75%"
                    alt="Logo"
                />
                <div className="text-lg uppercase text-gray-400">
                    Registrieren
                </div>
            </div>
            <input
                className="w-full mb-2 p-2 bg-transparent focus:outline-none focus:border-accent outline-none border-b transition-all"
                placeholder="hugo@musterhase.de"
                type="email"
                required
                value={email}
                onChange={(event) => setEmail(event.target.value)}
            />
            <input
                className="w-full p-2 bg-transparent focus:outline-none focus:border-accent outline-none border-b transition-all"
                placeholder="HugosPasswort!"
                type="password"
                required
                value={password}
                onChange={(event) => setPassword(event.target.value)}
            />
            <div className="flex mt-8 justify-between">
                <input
                    type="submit"
                    value="Zurück"
                    onClick={() => navigate('/')}
                    className="bg-transparent py-2 font-light rounded-md text-accent hover:opacity-90 active:opacity-70 cursor-pointer"
                />
                <input
                    type="submit"
                    value="Account erstellen"
                    onClick={() => logUserIn()}
                    className="bg-accent px-4 py-2 font-semibold rounded-md text-white hover:opacity-90 active:opacity-70 cursor-pointer"
                />
            </div>
        </div>
    );
}

export default SignUp;
