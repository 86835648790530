import React from 'react';

function Settings(props) {
    return (
        <div className="w-full">
            <div className="font-bold text-2xl mb-6">Einstellungen</div>
            <div className="flex flex-row ">
                <div className="w-1/4 border-r border-gray-200">
                    <div>Workspace</div>
                    <div>Nutzer</div>
                </div>
                <div className="w-3/4"></div>
            </div>
        </div>
    );
}

export default Settings;
