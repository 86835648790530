import axios from 'axios';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { selectToken } from '../../../lib/stores/userStore';
import { selectSelectedWorkspace } from '../../../lib/stores/workspaceStore';

function AddCustomer(props) {
    const navigate = useNavigate();

    const [customerName, setCustomerName] = useState('');
    const selectedWorkspace = useSelector(selectSelectedWorkspace);
    const token = useSelector(selectToken);

    function addCustomer() {
        axios
            .post(
                process.env.REACT_APP_API_URL + '/customer/addCustomer',
                {
                    'companyName': customerName,
                    'workspaceID': selectedWorkspace,
                    'type': 'company',
                },
                {
                    'headers': {
                        'content-type': 'application/json',
                        'auth-token': token,
                    },
                }
            )
            .then((res) => {
                if (res.status === 200) {
                    props.onClose();
                }
            });
    }

    return (
        <div className="w-full h-full flex items-center justify-center">
            <div className="w-96">
                <div className="w-full text-2xl text-gray-600 mb-12">
                    Kunden hinzufügen
                </div>
                <input
                    className="w-full p-2 bg-transparent focus:outline-none focus:border-accent outline-none border-b transition-all"
                    placeholder="Hugos mega guter Kunde"
                    type="text"
                    required
                    value={customerName}
                    onChange={(event) => setCustomerName(event.target.value)}
                />
                <div className="w-full flex mt-8 justify-between items-center">
                    <span
                        className="material-icons text-accent text-3xl flex items-center justify-center cursor-pointer active:opacity-70"
                        onClick={() => props.onClose()}
                    >
                        arrow_back
                    </span>

                    <input
                        type="submit"
                        value="Projekt erstellen"
                        className="bg-accent px-4 py-2 font-semibold rounded-md text-white hover:opacity-90 active:opacity-70 cursor-pointer"
                        onClick={() => addCustomer()}
                    />
                </div>
            </div>
        </div>
    );
}

export default AddCustomer;
