import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams, useNavigate } from 'react-router';
import { selectToken } from '../../../lib/stores/userStore';
import { selectSelectedWorkspace } from '../../../lib/stores/workspaceStore';

function CustomerInfo(navigation) {
    let { id } = useParams();

    const navigate = useNavigate();

    const workspaceID = useSelector(selectSelectedWorkspace);
    const token = useSelector(selectToken);

    const [customerData, setCustomerData] = useState([]);

    useEffect(() => {
        axios
            .get(
                process.env.REACT_APP_API_URL +
                    '/customer/getCustomer/' +
                    workspaceID +
                    '/' +
                    id,

                {
                    'headers': {
                        'content-type': 'application/json',
                        'auth-token': token,
                    },
                }
            )
            .then((res) => {
                if (res.status === 200) {
                    setCustomerData(res.data);
                }
            });
    }, [id]);

    return (
        <div className="w-full h-full ">
            <div className="w-full flex justify-between items-center">
                <div className="text-4xl text-gray-600 flex justify-center items-center">
                    <div
                        className="group px-3 py-2 text-base text-accent border border-accent rounded-lg mr-8 flex justify-center items-center cursor-pointer transition-all hover:bg-accentDark hover:border-accentDark hover:text-white"
                        onClick={() => navigate('/customer/')}
                    >
                        <span class="material-icons text-lg mr-2 transition-all group-hover:text-white">
                            arrow_back
                        </span>
                        Zurück
                    </div>
                </div>
                <div className="flex">
                    <div>
                        {customerData.type === 'person' ? (
                            <div>Person</div>
                        ) : (
                            <div>Unternehmen</div>
                        )}
                    </div>
                </div>
            </div>
            <div className="grid grid-cols-1 gap-10 mt-10 md:grid-cols-2">
                <div>
                    <div className="w-full rounded-xl bg-white shadow-xl py-6 px-8 flex items-center overflow-hidden">
                        <div className="w-28 h-28 bg-gray-400 rounded-xl flex justify-center items-center">
                            <span className="material-icons text-6xl text-white">
                                {customerData.type === 'company'
                                    ? 'business'
                                    : 'person'}
                            </span>
                        </div>
                        <div className="ml-6">
                            <div className="text-2xl text-gray-800 font-bold">
                                {customerData.companyName}
                            </div>
                            <div>Herr Max Mustermann</div>
                            <div>Musterstraße 1</div>
                            <div>24819 Musterstadt</div>
                        </div>
                    </div>
                    <div className="w-full rounded-xl bg-white shadow-xl py-6 px-12 mt-10 overflow-hidden">
                        <div className="text-xl text-gray-800 font-bold pb-5">
                            Beschreibung
                        </div>
                        <div>{customerData.desc}</div>
                    </div>
                </div>
                <div className="w-full rounded-xl bg-white shadow-xl py-6 px-12 overflow-hidden">
                    <div className="text-xl text-gray-800 font-bold pb-5">
                        Interaktionen
                    </div>
                </div>
                <div className="w-full rounded-xl bg-white shadow-xl py-6 px-12 overflow-hidden">
                    <div className="text-xl text-gray-800 font-bold pb-5">
                        Kontakt
                    </div>
                </div>
            </div>
        </div>
    );
}

export default CustomerInfo;
