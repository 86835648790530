import React from 'react';
import dateFormat from 'dateformat';

function EditStaff(props) {
    return (
        <div style={{ 'width': (window.innerWidth / 5) * 2 }}>
            <div className="flex justify-between items-start">
                <div className="font-light mb-2">
                    <h1 className="text-2xl font-semibold text-gray-800">
                        Mitarbeiter bearbeiten
                    </h1>
                </div>
                <span
                    className="material-icons text-accent text-3xl flex items-center justify-center cursor-pointer active:opacity-70"
                    onClick={() => props.onClose()}
                >
                    close
                </span>
            </div>
            <div className="flex justify-between ">
                <div className="w-full">
                    <div className='w-full'>
                        <input
                            className="w-1/3 p-2 bg-transparent focus:outline-none focus:border-accent outline-none border-b transition-all"
                            placeholder="Vorname"
                            type="text"
                            required
                        />
                        <input
                            className="w-1/3 p-2 bg-transparent focus:outline-none focus:border-accent outline-none border-b transition-all"
                            placeholder="Name"
                            type="text"
                            required
                        />
                    </div>
                    <div className='w-full'>
                        <input
                            className="w-1/3 p-2 bg-transparent focus:outline-none focus:border-accent outline-none border-b transition-all"
                            placeholder="Email Adresse"
                            type="text"
                            required
                        />
                    </div>
                    <div className='w-full'>
                        <input
                            className="w-1/3 p-2 bg-transparent focus:outline-none focus:border-accent outline-none border-b transition-all"
                            placeholder="RFID-ID"
                            type="text"
                            required
                        />
                    </div>
                </div>
                <div className="w-1/5 pl-5 flex flex-col border-l cursor-pointer border-gray-200">
                    <div className="group flex bg-primary text-white mb-4 rounded-md overflow-hidden transition-all hover:bg-accentDark">
                        <div className="flex px-3 py-2">
                            <span className="material-icons pr-2">cloud_upload</span>
                            Übernehmen
                        </div>
                    </div>
                </div>
            </div>
            <div className="flex"></div>
        </div>
    );
}

export default EditStaff;
