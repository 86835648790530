import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    userData: null,
    token: null,
    workspaceUserInfo: null,
};

export const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        setUserData: (state, action) => {
            if (action.payload) {
                state.userData = action.payload;
            }
        },
        setToken: (state, action) => {
            if (action.payload) {
                state.token = action.payload;
            }
        },
        setWorkspaceUserInfo: (state, action) => {
            if (action.payload) {
                state.workspaceUserInfo = action.payload;
            }
        },
    },
});

export const { setUserData, setToken, setWorkspaceUserInfo } =
    userSlice.actions;

export const selectUserData = (state) => state.user.userData;
export const selectToken = (state) => state.user.token;
export const selectWorkspaceUserInfo = (state) => state.user.workspaceUserInfo;

export default userSlice.reducer;
