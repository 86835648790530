import axios from 'axios';
import { createHash } from 'crypto';
import React, { useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { useLocation, useNavigate } from 'react-router-dom';

import WeboardLogo from '../assets/WebordLogo.png';
import WeboardLogoColored from '../assets/WebordLogoColored.png';

function useQuery() {
    const { search } = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
}

function AcceptInvite() {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    let query = useQuery();

    const navigation = useNavigate();

    const token = query.get('token');
    const workspaceID = query.get('wk');

    useEffect(() => {
        console.log(token);
    }, []);

    function acceptInvite() {
        const hashedPassword = createHash('sha256')
            .update(password)
            .digest('hex');
        axios
            .post(
                process.env.REACT_APP_API_URL + '/staff/acceptInvite',
                {
                    'inviteToken': token,
                    'email': email,
                    'password': hashedPassword,
                    'workspaceID': workspaceID,
                },
                {
                    'headers': {
                        'content-type': 'application/json',
                    },
                }
            )
            .then((res) => {
                if (res.status === 200) {
                    navigation('/');
                }
            });
    }

    return (
        <div className="max-w-md m-8">
            <div className="w-full flex flex-col items-center justify-center mb-12">
                <img
                    src={isMobile ? WeboardLogo : WeboardLogoColored}
                    width="75%"
                    alt="Logo"
                />
                <div className="text-lg uppercase text-gray-400">
                    EINLADUNG ANNEHMEN
                </div>
            </div>
            <input
                className="w-full mb-2 p-2 bg-transparent focus:outline-none focus:border-accent outline-none border-b transition-all"
                placeholder="hugo@musterhase.de"
                type="email"
                required
                value={email}
                onChange={(event) => setEmail(event.target.value)}
            />
            <input
                className="w-full p-2 bg-transparent focus:outline-none focus:border-accent outline-none border-b transition-all"
                placeholder="HugosPasswort!"
                type="password"
                required
                value={password}
                onChange={(event) => setPassword(event.target.value)}
            />
            <div className="flex mt-8 justify-end">
                <input
                    type="submit"
                    value="Anmelden"
                    onClick={() => acceptInvite()}
                    className="bg-accent px-4 py-2 font-semibold rounded-md text-white hover:opacity-90 active:opacity-70 cursor-pointer"
                />
            </div>
        </div>
    );
}

export default AcceptInvite;
