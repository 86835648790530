import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    allProjects: [],
    selectedProject: [],
};

export const projectSlice = createSlice({
    name: 'project',
    initialState,
    reducers: {
        setAllProjects: (state, action) => {
            if (action.payload) {
                state.allProjects = action.payload;
            }
        },
        setSelectedProject: (state, action) => {
            if (action.payload) {
                state.selectedProject = action.payload;
            }
        },
    },
});

export const { setAllProjects } = projectSlice.actions;
export const { setSelectedProject } = projectSlice.actions;

export const selectAllProjects = (state) => state.project.allProjects;
export const selectSelectedProject = (state) => state.project.selectedProject;

export default projectSlice.reducer;
