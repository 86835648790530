import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import {
    selectToken,
    selectUserData,
    setUserData,
} from '../lib/stores/userStore';
import {
    selectSelectedWorkspace,
    setSelectedWorkspace,
} from '../lib/stores/workspaceStore';

function ChangeWorkspace(props) {
    const token = useSelector(selectToken);
    const selectedWorkspace = useSelector(selectSelectedWorkspace);
    const userData = useSelector(selectUserData);

    const navigate = useNavigate();

    const dispatch = useDispatch();

    const [allWorkspaces, setAllWorkspaces] = useState([]);

    useEffect(() => {
        axios
            .get(process.env.REACT_APP_API_URL + '/user/getAllWorkspaces/', {
                'headers': {
                    'content-type': 'application/json',
                    'auth-token': token,
                },
            })
            .then((res) => {
                if (res.status === 200) {
                    setAllWorkspaces(res.data);
                }
            });
    }, []);

    function changeWorkspace(workspaceID) {
        axios
            .patch(
                process.env.REACT_APP_API_URL + '/workspace/selectWorkspace/',
                { 'id': workspaceID },
                {
                    'headers': {
                        'content-type': 'application/json',
                        'auth-token': token,
                    },
                }
            )
            .then((res) => {
                if (res.status === 200) {
                    dispatch(setSelectedWorkspace(workspaceID));
                    dispatch(setUserData(res.data));
                    props.onClose();
                }
            });
    }

    return (
        <div className="w-96">
            <div className="flex justify-between items-center pb-6">
                <div className="text-2xl px-2  font-semibold">
                    Workspace wechseln
                </div>
                <span
                    className="material-icons w-10 h-10 bg-accent flex justify-center items-center rounded-full text-white cursor-pointer hover:bg-accentDark transition-all"
                    onClick={() => props.onClose()}
                >
                    close
                </span>
            </div>
            <div className="w-full">
                {allWorkspaces.map(({ data, id }, index) => (
                    <div
                        key={index}
                        onClick={() => changeWorkspace(id)}
                        className="w-full border-t border-gray-200 py-4 px-2 flex justify-between transition-all hover:bg-gray-100 cursor-pointer"
                    >
                        <div className="flex flex-col justify-center ">
                            <div className="font-normal text-lg">
                                {data.name}
                            </div>
                            <div className="font-light">
                                {data.users.length + ' Nutzer'}
                            </div>
                        </div>

                        <div className="flex justify-center items-center">
                            {id === selectedWorkspace ? (
                                <span
                                    className="material-icons text-primary rounded-full h-10 w-10 flex justify-center items-center mr-1 cursor-pointer transition-all hover:bg-gray-200"
                                    onClick={() => {}}
                                >
                                    check
                                </span>
                            ) : (
                                <span
                                    className="material-icons text-accent rounded-full h-10 w-10 flex justify-center items-center mr-1 cursor-pointer transition-all hover:bg-gray-200"
                                    onClick={() => {}}
                                >
                                    chevron_right
                                </span>
                            )}
                        </div>
                    </div>
                ))}
                <div
                    className="w-full border-t border-gray-200 py-4 px-2 flex justify-between rounded-b-xl transition-all hover:bg-gray-100 cursor-pointer"
                    onClick={() => props.openCreateWorkspace()}
                >
                    <div className="flex flex-col justify-center ">
                        <div className="font-light text-lg">
                            Workspace hinzufügen
                        </div>
                    </div>

                    <div className="flex justify-center items-center">
                        <span className="material-icons text-gray-400 rounded-full h-10 w-10 flex justify-center items-center mr-1 cursor-pointer transition-all hover:bg-gray-200">
                            add
                        </span>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ChangeWorkspace;
