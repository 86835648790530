import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
    selectAllWorkspaces,
    selectSelectedWorkspace,
    selectSelectedWorkspaceInfo,
} from '../../lib/stores/workspaceStore';
import { selectToken, selectUserData } from '../../lib/stores/userStore';

function Dashboard(props) {
    const userData = useSelector(selectUserData);
    const token = useSelector(selectToken);

    const allWorkspaces = useSelector(selectAllWorkspaces);
    const selectedWorkspace = useSelector(selectSelectedWorkspace);
    const selectedWorkspaceInfo = useSelector(selectSelectedWorkspaceInfo);

    const [numberOfTickets, setNumberOfTickets] = useState(0);
    const [numberOfQuickTasks, setNumberOfQuickTasks] = useState(0);

    useEffect(() => {
        axios
            .get(
                process.env.REACT_APP_API_URL +
                    '/project/getNumberofAllTickets/' +
                    selectedWorkspace,
                {
                    'headers': {
                        'content-type': 'application/json',
                        'auth-token': token,
                    },
                }
            )
            .then((res) => {
                if (res.status === 200) {
                    setNumberOfTickets(res.data);
                }
            });
    }, [selectedWorkspace]);

    useEffect(() => {
        axios
            .get(
                process.env.REACT_APP_API_URL +
                    '/workspace/' +
                    selectedWorkspace +
                    '/user/getNumberOfAllQuickTasks',

                {
                    'headers': {
                        'content-type': 'application/json',
                        'auth-token': token,
                    },
                }
            )
            .then((res) => {
                if (res.status === 200) {
                    setNumberOfQuickTasks(res.data);
                }
            });
    }, [selectedWorkspace]);

    return (
        <div className="w-full h-full">
            <div className="w-full flex justify-between">
                <div className="text-4xl text-gray-600 flex items-center">
                    Dashboard
                    <div className="text-2xl ml-3 font-light">
                        ({selectedWorkspaceInfo?.name})
                    </div>
                </div>
                <div className="flex"></div>
            </div>
            <div className="max-w-full mt-10">
                <div className="w-1/3 flex rounded-xl bg-white shadow-xl p-6 overflow-hidden">
                    <div className="w-1/2 flex flex-col justify-center items-center">
                        <div className="text-3xl">{numberOfTickets}</div>
                        <div className="text-sm">Offene Tickets</div>
                    </div>
                    <div className="w-1/2 flex flex-col justify-center items-center border-l border-gray-300">
                        <div className="text-3xl">{numberOfQuickTasks}</div>
                        <div className="text-sm">Offene Quicktask</div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Dashboard;
