import React, { useEffect, useState } from 'react';
import './toastAnim.css';

function ToastNotificationContainer(props) {
    const toastList = props.toastList;
    const [list, setList] = useState(toastList);

    useEffect(() => {
        setList(toastList);
    }, [toastList, list]);

    useEffect(() => {
        const interval = setInterval(() => {
            if (toastList.length && list.length) {
                deleteToast(0);
            }
        }, 2500);
        return () => {
            clearInterval(interval);
        };
    }, []);

    function deleteToast(toastListItemIndex) {
        toastList.splice(toastListItemIndex, 1);
        setList(toastList);
    }

    return (
        <div className="absolute bottom-6 right-6 w-1/3 overflow-hidden">
            {list.map((item, index) => (
                <ToastNotificationTemplate
                    key={index}
                    id={index}
                    title={item.title}
                    description={item.desc}
                    type={item.type}
                    onDelete={(index) => deleteToast(index)}
                />
            ))}
        </div>
    );
}

function ToastNotificationTemplate({
    title = 'Meldung',
    description = '',
    type = 'normal',
    onDelete,
    id,
}) {
    console.log(title);
    var bgColor;
    var iconName;

    switch (type) {
        case 'normal':
            bgColor = 'bg-white';
            iconName = 'info';
            break;

        case 'info':
            bgColor = 'bg-primary';
            iconName = 'info';
            break;

        case 'warning':
            bgColor = 'bg-yellow-400';
            iconName = 'warning';
            break;

        case 'error':
            bgColor = 'bg-red-600 text-white';
            iconName = 'close';
            break;

        case 'success':
            bgColor = 'bg-green-500';
            iconName = 'done';
            break;

        default:
            bgColor = 'bg-white';
            iconName = 'info';
            break;
    }

    return (
        <div
            className={
                'slideIn w-full py-3 px-6 rounded-xl shadow-2xl flex items-center ' +
                bgColor
            }
            key={id}
            onClick={() => onDelete(id)}
        >
            <span className="material-icons text-3xl mr-4">{iconName}</span>
            <div>
                <div className="text-xl font-semibold">{title}</div>
                <div className="text-md font-normal">{description}</div>
            </div>
        </div>
    );
}

export default ToastNotificationContainer;
