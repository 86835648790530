import React from 'react';

function EditTicket(props) {
    return (
        <div>
            Bearbeiten
            <span
                className="material-icons text-accent text-3xl flex items-center justify-center cursor-pointer active:opacity-70"
                onClick={() => props.onClose()}
            >
                arrow_back
            </span>
        </div>
    );
}

export default EditTicket;
