import React from 'react';
import { Outlet } from 'react-router';

function ProjectContainer() {
    return (
        <div className="w-full h-full">
            <Outlet />
        </div>
    );
}

export default ProjectContainer;
