import axios from 'axios';
import React from 'react';
import { useSelector } from 'react-redux';
import { selectToken } from '../../../lib/stores/userStore';

function DeleteTicket(props) {
    const data = props.data;

    const token = useSelector(selectToken);

    function deleteTicket() {
        console.log(data);
        axios
            .delete(
                process.env.REACT_APP_API_URL +
                    '/project/deleteTicket/' +
                    data.workspaceID +
                    '/' +
                    data.projectID +
                    '/' +
                    data.ticketID,
                {
                    'headers': {
                        'content-type': 'application/json',
                        'auth-token': token,
                    },
                }
            )
            .then((res) => {
                console.log(res.data);
                if (res.status === 200) {
                    props.onClose();
                }
            });
    }

    return (
        <div className="w-96">
            <div className="w-full text-2xl text-gray-900 mb-4">
                Ticket hinzufügen
            </div>
            <div className="text-gray-700">
                Willst du dieses Ticket wirklich löschen?
            </div>
            <div className="w-full flex mt-8 justify-between items-center">
                <span
                    className="material-icons text-accent text-3xl flex items-center justify-center cursor-pointer active:opacity-70"
                    onClick={() => props.onClose()}
                >
                    arrow_back
                </span>

                <input
                    type="submit"
                    value="Ja, jetzt löschen"
                    className="bg-accent px-4 py-2 font-semibold rounded-md text-white hover:opacity-90 active:opacity-70 cursor-pointer"
                    onClick={() => deleteTicket()}
                />
            </div>
        </div>
    );
}

export default DeleteTicket;
