import React, { useState } from 'react';
import loginIllustration from '../../assets/loginIllustration.png';
import WeboardLogo from '../../assets/WebordLogo.png';
import WeboardLogoColored from '../../assets/WebordLogoColored.png';
import { BrowserView, isMobile, MobileView } from 'react-device-detect';
import { useDispatch, useSelector } from 'react-redux';
import { selectToken, setUserData } from '../../lib/stores/userStore';
import axios from 'axios';
import { useNavigate } from 'react-router';
import { setSelectedWorkspace } from '../../lib/stores/workspaceStore';

function WizardContainer() {
    const [workspaceName, setWorkspaceName] = useState('');
    const navigate = useNavigate();
    const token = useSelector(selectToken);

    const dispatch = useDispatch();

    function createWorkspace() {
        if (workspaceName !== '') {
            axios
                .post(
                    process.env.REACT_APP_API_URL + '/workspace/addWorkspace',
                    {
                        'name': workspaceName,
                    },
                    {
                        'headers': {
                            'content-type': 'application/json',
                            'auth-token': token,
                        },
                    }
                )
                .then((res) => {
                    if (res.status === 200) {
                        const workspaceID = res.data;
                        dispatch(setSelectedWorkspace(res.data));
                        axios
                            .patch(
                                process.env.REACT_APP_API_URL +
                                    '/user/updateFirstVisit',
                                {},
                                {
                                    'headers': {
                                        'content-type': 'application/json',
                                        'auth-token': token,
                                    },
                                }
                            )
                            .then((res) => {
                                if (res.status === 200) {
                                    console.log(res.data);
                                    axios
                                        .patch(
                                            process.env.REACT_APP_API_URL +
                                                '/workspace/selectWorkspace',
                                            { 'id': workspaceID },
                                            {
                                                'headers': {
                                                    'content-type':
                                                        'application/json',
                                                    'auth-token': token,
                                                },
                                            }
                                        )
                                        .then((res) => {
                                            if (res.status === 200) {
                                                console.log(res.data);
                                                dispatch(setUserData(res.data));
                                                navigate('/');
                                            }
                                        });
                                }
                            });
                    }
                });
        }
    }

    function skip() {
        axios
            .patch(
                process.env.REACT_APP_API_URL + '/user/updateFirstVisit',
                {},
                {
                    'headers': {
                        'content-type': 'application/json',
                        'auth-token': token,
                    },
                }
            )
            .then((res) => {
                if (res.status === 200) {
                    console.log(res.data);
                    dispatch(setUserData(res.data));
                    navigate('/');
                }
            });
    }

    return (
        <div className="w-full h-full flex bg-background">
            <BrowserView className="w-full h-full flex">
                <div className="w-1/2 h-full shadow-2xl rounded-r-6xl bg-primary flex justify-center items-center">
                    <img src={loginIllustration} className="w-1/2" />
                </div>
                <div className="w-1/2 h-full flex justify-center items-center">
                    <div className="max-w-md m-8">
                        <div className="w-full flex flex-col items-center justify-center mb-12">
                            <img
                                src={
                                    isMobile ? WeboardLogo : WeboardLogoColored
                                }
                                width="75%"
                                alt="Logo"
                            />
                            <div className="text-lg uppercase text-gray-400">
                                Workspace erstellen
                            </div>
                        </div>
                        <input
                            className="w-full mb-2 p-2 bg-transparent focus:outline-none focus:border-accent outline-none border-b transition-all"
                            placeholder="Hugos Musterhasen UG"
                            type="text"
                            required
                            value={workspaceName}
                            onChange={(event) =>
                                setWorkspaceName(event.target.value)
                            }
                        />

                        <div className="flex mt-8 justify-between">
                            <input
                                type="submit"
                                value="Überspringen"
                                className="bg-transparent py-2 font-light rounded-md text-accent hover:opacity-90 active:opacity-70 cursor-pointer"
                                onClick={() => skip()}
                            />
                            <input
                                type="submit"
                                value="Erstellen"
                                className="bg-accent px-4 py-2 font-semibold rounded-md text-white hover:opacity-90 active:opacity-70 cursor-pointer"
                                onClick={() => createWorkspace()}
                            />
                        </div>
                    </div>
                </div>
            </BrowserView>
            <MobileView className="w-full h-full bg-mobileLoginBG bg-cover">
                <div className="h-1/6" />
                <div className="h-5/6 w-full flex justify-center items-center"></div>
            </MobileView>
        </div>
    );
}

export default WizardContainer;
