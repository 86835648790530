import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectToken, selectUserData } from '../../lib/stores/userStore';
import {
    selectSelectedWorkspace,
    selectSelectedWorkspaceInfo,
} from '../../lib/stores/workspaceStore';

function YourDay() {
    const selectedWorkspaceID = useSelector(selectSelectedWorkspace);
    const token = useSelector(selectToken);
    const userInfo = useSelector(selectUserData);

    const selectedWorkspaceInfo = useSelector(selectSelectedWorkspaceInfo);

    const [quickTaskTitle, setQuickTaskTitle] = useState('');

    const [allQuickTasks, setAllQuickTasks] = useState([]);

    useEffect(() => {
        getAllQuickTask();
    }, [token, selectedWorkspaceID]);

    function getAllQuickTask() {
        axios
            .get(
                process.env.REACT_APP_API_URL +
                    '/workspace/' +
                    selectedWorkspaceID +
                    '/user/getAllQuickTasks',

                {
                    'headers': {
                        'content-type': 'application/json',
                        'auth-token': token,
                    },
                }
            )
            .then((res) => {
                if (res.status === 200) {
                    setAllQuickTasks(res.data);
                }
            });
    }

    function addNewQuickTask() {
        if (quickTaskTitle !== '') {
            axios
                .post(
                    process.env.REACT_APP_API_URL +
                        '/workspace/' +
                        selectedWorkspaceID +
                        '/user/addQuickTask',
                    {
                        taskTitle: quickTaskTitle,
                    },

                    {
                        'headers': {
                            'content-type': 'application/json',
                            'auth-token': token,
                        },
                    }
                )
                .then((res) => {
                    if (res.status === 200) {
                        setQuickTaskTitle('');
                        getAllQuickTask();
                    }
                });
        }
    }

    function checkQuickTask(taskID) {
        axios
            .patch(
                process.env.REACT_APP_API_URL +
                    '/workspace/' +
                    selectedWorkspaceID +
                    '/user/checkQuickTask/' +
                    taskID,
                {},
                {
                    'headers': {
                        'content-type': 'application/json',
                        'auth-token': token,
                    },
                }
            )
            .then((res) => {
                if (res.status === 200) {
                    getAllQuickTask();
                }
            });
    }

    return (
        <div className="w-full h-full">
            <div className="w-full flex justify-between">
                <div className="text-4xl text-gray-600 flex items-center">
                    Dein Tag
                    <div className="text-2xl ml-3 font-light">
                        ({selectedWorkspaceInfo?.name})
                    </div>
                </div>
                <div className="flex"></div>
            </div>
            <div className="w-1/3 rounded-xl p-4 bg-white shadow-xl mt-10 overflow-hidden">
                <div className="font-semibold text-xl text-gray-900">
                    Quick Task
                </div>
                <div className="w-full flex justify-between items-center">
                    <input
                        className="w-5/6 mt-2 py-2 bg-transparent focus:outline-none focus:border-accent outline-none border-b transition-all"
                        placeholder="Kunde XY schnell anrufen"
                        type="text"
                        required
                        onChange={(event) =>
                            setQuickTaskTitle(event.target.value)
                        }
                        value={quickTaskTitle}
                    />
                    <span
                        className="material-icons w-10 h-10 flex items-center justify-center cursor-pointer rounded-full text-white bg-accent hover:bg-accentDark transition-all"
                        onClick={() => addNewQuickTask()}
                    >
                        add
                    </span>
                </div>
                {allQuickTasks.length === 0 ? (
                    <div className="pb-4 pt-6 flex justify-between cursor-pointer">
                        <div className="flex flex-col justify-center ">
                            <div className="font-normal text-lg flex items-center">
                                Freu dich, hier bist du fertig!
                                <div className="text-3xl ml-1">&#128522;</div>
                            </div>
                        </div>
                    </div>
                ) : (
                    allQuickTasks.map(({ id, data }, index) => (
                        <div
                            key={id}
                            className="group border-b border-gray-200 py-4 pl-2 flex justify-between transition-all hover:bg-gray-100 cursor-pointer"
                        >
                            <div className="flex flex-col justify-center ">
                                <div className="font-normal text-lg">
                                    {data.title}
                                </div>
                            </div>

                            <div className="flex justify-center items-center">
                                <span
                                    className="material-icons text-gray-300 border border-gray-300 rounded-lg h-10 w-10 flex justify-center items-center mr-1 cursor-pointer transition-all group-hover:border-primary group-hover:bg-primary group-hover:text-white"
                                    onClick={() => checkQuickTask(id)}
                                >
                                    check
                                </span>
                            </div>
                        </div>
                    ))
                )}
            </div>
        </div>
    );
}

export default YourDay;
