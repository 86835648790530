import axios from 'axios';
import { createHash } from 'crypto';
import React, { useState } from 'react';
import { isMobile } from 'react-device-detect';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import WeboardLogo from '../assets/WebordLogo.png';
import WeboardLogoColored from '../assets/WebordLogoColored.png';
import { setToken, setUserData } from '../lib/stores/userStore';

function Login() {
    const navigate = useNavigate();

    const dispatch = useDispatch();

    console.log(process.env.REACT_APP_API_URL);

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    function logUserIn() {
        const hashedPassword = createHash('sha256')
            .update(password)
            .digest('hex');

        axios
            .post(
                process.env.REACT_APP_API_URL + '/auth/login',
                JSON.stringify({
                    'email': email,
                    'password': hashedPassword,
                }),
                {
                    'headers': {
                        'content-type': 'application/json',
                    },
                }
            )
            .then((res) => {
                const data = res.data;

                if (res.status === 200 && data) {
                    dispatch(setToken(data.token));
                    dispatch(setUserData(data.userData));
                }
            });
    }

    return (
        <div className="max-w-md m-8">
            <div className="w-full flex flex-col items-center justify-center mb-12">
                <img
                    src={isMobile ? WeboardLogo : WeboardLogoColored}
                    width="75%"
                    alt="Logo"
                />
                <div className="text-lg uppercase text-gray-400">Anmelden</div>
            </div>
            <input
                className="w-full mb-2 p-2 bg-transparent focus:outline-none focus:border-accent outline-none border-b transition-all"
                placeholder="hugo@musterhase.de"
                type="email"
                required
                value={email}
                onChange={(event) => setEmail(event.target.value)}
            />
            <input
                className="w-full p-2 bg-transparent focus:outline-none focus:border-accent outline-none border-b transition-all"
                placeholder="HugosPasswort!"
                type="password"
                required
                value={password}
                onChange={(event) => setPassword(event.target.value)}
            />
            <div className="flex mt-8 justify-between">
                <input
                    type="submit"
                    value="Passwort vergessen"
                    onClick={() => navigate('/forgotPassword')}
                    className="bg-transparent py-2 font-light rounded-md text-accent hover:opacity-90 active:opacity-70 cursor-pointer"
                />
                <input
                    type="submit"
                    value="Anmelden"
                    onClick={() => logUserIn()}
                    className="bg-accent px-4 py-2 font-semibold rounded-md text-white hover:opacity-90 active:opacity-70 cursor-pointer"
                />
            </div>
            <div className="w-full p-24 pt-12 pb-6">
                <hr />
            </div>
            <div className="flex justify-center">
                <input
                    type="submit"
                    value="Registrieren"
                    onClick={() => navigate('/signup')}
                    className="bg-transparent py-2 font-light rounded-md text-accent hover:opacity-90 active:opacity-70 cursor-pointer"
                />
            </div>
        </div>
    );
}

export default Login;
