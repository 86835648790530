import React from 'react';
import AddCustomer from '../screen/appScreens/customer/addCustomer';
import DeleteTicket from '../screen/appScreens/projects/deleteTicket';
import EditTicket from '../screen/appScreens/projects/editTicket';
import TicketCreate from '../screen/appScreens/projects/ticketCreate';
import TicketDetail from '../screen/appScreens/projects/ticketDetail';
import ChangeWorkspace from '../screen/changeWorkspace';
import CreateWorkspace from '../screen/createWorkspace';
import InviteStaff from '../screen/appScreens/staff/inviteStaff';
import ProjectCreate from '../screen/appScreens/projects/projectCreate';
import EditStaff from '../screen/appScreens/staff/editStaff';

function Modal(props) {
    var ModalContent = props.element;
    const showModal = props.shown;

    switch (ModalContent) {
        case 'createProject':
            ModalContent = ProjectCreate;
            break;

        case 'createTicket':
            ModalContent = TicketCreate;
            break;

        case 'deleteTicket':
            ModalContent = DeleteTicket;
            break;

        case 'ticketDetail':
            ModalContent = TicketDetail;
            break;

        case 'editTicket':
            ModalContent = EditTicket;
            break;

        case 'addCustomer':
            ModalContent = AddCustomer;
            break;

        case 'changeWorkspace':
            ModalContent = ChangeWorkspace;
            break;

        case 'createWorkspace':
            ModalContent = CreateWorkspace;
            break;

        case 'inviteStaff':
            ModalContent = InviteStaff;
            break;
        
        case 'editStaff':
            ModalContent = EditStaff;
            break;

        default:
            ModalContent = function () {
                return (
                    <div className="w-96">
                        <div className="w-full text-2xl text-gray-900 mb-4">
                            Fehler
                        </div>
                        <div className="text-gray-700 mb-8">
                            Beim öffnen des Fenster ist ein Fehler aufgetreten.
                        </div>
                        <div className="flex justify-end">
                            <input
                                type="submit"
                                value="Abbrechen"
                                className="bg-accent px-4 py-2 font-semibold rounded-md text-white hover:opacity-90 active:opacity-70 cursor-pointer"
                                onClick={() => props.onClose()}
                            />
                        </div>
                    </div>
                );
            };
            break;
    }

    return showModal ? (
        <div className="absolute top-0 left-0 right-0 bottom-0 bg-gray-900 bg-opacity-30 z-40 flex justify-center items-center">
            <div
                className="bg-white p-12 rounded-xl shadow-xl z-50 "
                onClick={() => { }}
            >
                <ModalContent
                    onClose={() => props.onClose()}
                    openCreateWorkspace={() => props.openCreateWorkspace()}
                    data={props.data}
                />
            </div>
        </div>
    ) : (
        <></>
    );
}

export default Modal;
