import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { BrowserView, MobileView } from 'react-device-detect';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Routes, useNavigate, useLocation, Outlet } from 'react-router';
import {
    selectSelectedWorkspace,
    selectSelectedWorkspaceInfo,
    setAllWorkspaces,
    setSelectedWorkspace,
    setSelectedWorkspaceInfo,
} from '../lib/stores/workspaceStore';
import {
    selectToken,
    selectUserData,
    selectWorkspaceUserInfo,
    setWorkspaceUserInfo,
} from '../lib/stores/userStore';
import Account from './appScreens/account';
import CustomerOverview from './appScreens/customer/customerOverview';
import Dashboard from './appScreens/dashboard';
import Settings from './appScreens/settings';
import ProjectDashboard from './appScreens/projects/projectDashboard';
import ProjectContainer from './appScreens/projects/projectContainer';
import YourDay from './appScreens/yourDay';
import CustomerContainer from './appScreens/customer/customerContainer';
import CustomerInfo from './appScreens/customer/customerInfo';
import Modal from '../lib/Modal';
import NotFound from './appScreens/404';
import StaffContainer from './appScreens/staff/staffContainer';
import StaffDashboard from './appScreens/staff/staffDashboard';

function AppContainer() {
    const token = useSelector(selectToken);
    const selectedWorkspace = useSelector(selectSelectedWorkspace);
    const selectedWorkspaceInfo = useSelector(selectSelectedWorkspaceInfo);
    const userData = useSelector(selectUserData);
    const workspaceUserInfo = useSelector(selectWorkspaceUserInfo);

    const navigate = useNavigate();
    const location = useLocation();

    const [selectedNavItem, setSelectedNavItem] = useState(0);

    const [modalScreenOpen, setModalScreenOpen] = useState(false);
    const [modalData, setModalData] = useState({});
    const [modalScreen, setModalScreen] = useState('');

    const dispatch = useDispatch();

    const navArray = [
        { iconName: 'home', path: '/' },
        { iconName: 'list', path: '/your-day' },
        { iconName: 'view_kanban', path: '/projects' },
        { iconName: 'support_agent', path: '/customer' },
        { iconName: 'people', path: '/staff' },
        { iconName: 'credit_card', path: '/cards' },
    ];

    useEffect(() => {
        if (userData) {
            dispatch(setSelectedWorkspace(userData.selectedWorkspace));
        }
    }, [userData]);

    useEffect(() => {
        axios
            .get(
                process.env.REACT_APP_API_URL +
                    '/workspace/getWorkspace/' +
                    selectedWorkspace,
                {
                    'headers': {
                        'content-type': 'application/json',
                        'auth-token': token,
                    },
                }
            )
            .then((res) => {
                if (res.status === 200) {
                    dispatch(setSelectedWorkspaceInfo(res.data));
                }
            });
    }, [selectedWorkspace, userData]);

    useEffect(() => {
        axios
            .get(
                process.env.REACT_APP_API_URL +
                    '/user/getWorkspaceUserInfo/' +
                    selectedWorkspace,
                {
                    'headers': {
                        'content-type': 'application/json',
                        'auth-token': token,
                    },
                }
            )
            .then((res) => {
                if (res.status === 200) {
                    dispatch(setWorkspaceUserInfo(res.data));
                }
            });
    }, [selectedWorkspace, location]);

    useEffect(() => {
        if (selectedWorkspaceInfo) {
            document.title = selectedWorkspaceInfo?.name + ' - Webord';
        } else {
            document.title = 'Webord';
        }
    }, [selectedWorkspaceInfo]);

    useEffect(() => {
        axios
            .get(
                process.env.REACT_APP_API_URL + '/workspace/getAllWorkspaces',
                {
                    'headers': {
                        'content-type': 'application/json',
                        'auth-token': token,
                    },
                }
            )
            .then((res) => {
                if (res.status === 200) {
                    dispatch(setAllWorkspaces(res.data));
                }
            });
    }, []);

    const CustomRoutes = () => {
        return <Routes></Routes>;
    };

    function openModal(Screen, data = {}) {
        setModalScreenOpen(true);
        setModalData(data);

        setModalScreen(Screen);
    }

    return (
        <>
            <BrowserView className="w-full h-full flex">
                <Modal
                    element={modalScreen}
                    shown={modalScreenOpen}
                    data={modalData}
                    onClose={() => setModalScreenOpen(!modalScreenOpen)}
                    openCreateWorkspace={() => openModal('createWorkspace', {})}
                />
                <div className="h-full bg-white flex flex-col justify-between shadow-xl z-10">
                    <div className="my-4"></div>
                    <div>
                        {navArray.map((item, index) => (
                            <div
                                key={index}
                                className={
                                    (selectedNavItem === index
                                        ? 'bg-primary'
                                        : 'bg-green-400 bg-opacity-20 group') +
                                    ' w-full py-4 px-6 mb-2 transition-all hover:bg-primary cursor-pointer'
                                }
                                onClick={() => {
                                    navigate(item.path);
                                    setSelectedNavItem(index);
                                }}
                            >
                                <span className="material-icons text-3xl group-hover:text-white transition-all">
                                    {item.iconName}
                                </span>
                            </div>
                        ))}
                    </div>
                    <div className="my-4">
                        <div
                            className={
                                'group w-full py-4 px-6 transition-all hover:bg-gray-800 cursor-pointer'
                            }
                            onClick={() => {
                                openModal('changeWorkspace', {});
                            }}
                        >
                            <span
                                className={
                                    'material-icons text-3xl group-hover:text-white transition-all text-gray-900'
                                }
                            >
                                business
                            </span>
                        </div>
                        <div
                            className={
                                'group w-full py-4 px-6 transition-all hover:bg-gray-500 cursor-pointer ' +
                                (selectedNavItem === navArray.length + 2
                                    ? 'bg-gray-800'
                                    : 'bg-transparent ')
                            }
                            onClick={() => {
                                navigate('/account');
                                setSelectedNavItem(navArray.length + 2);
                            }}
                        >
                            <span
                                className={
                                    'material-icons text-3xl group-hover:text-white transition-all ' +
                                    (selectedNavItem === navArray.length + 2
                                        ? 'text-white'
                                        : 'text-black')
                                }
                            >
                                account_circle
                            </span>
                        </div>
                        <div
                            className={
                                'group w-full py-4 px-6 transition-all hover:bg-gray-500 cursor-pointer ' +
                                (selectedNavItem === navArray.length + 3
                                    ? 'bg-gray-800'
                                    : 'bg-transparent')
                            }
                            onClick={() => {
                                navigate('/settings');
                                setSelectedNavItem(navArray.length + 3);
                            }}
                        >
                            <span
                                className={
                                    'material-icons text-3xl group-hover:text-white transition-all ' +
                                    (selectedNavItem === navArray.length + 3
                                        ? 'text-white'
                                        : 'text-black')
                                }
                            >
                                settings
                            </span>
                        </div>
                    </div>
                </div>
                <div className="h-full w-full bg-gray-100 p-12">
                    <Outlet />
                </div>
            </BrowserView>
            <MobileView className="w-full h-full flex flex-col-reverse">
                <div className="w-full bg-white flex justify-center">
                    <div className="my-4 flex"></div>
                    <div className="flex">
                        {navArray.map((item, index) => (
                            <div
                                key={index}
                                className={
                                    (selectedNavItem === index
                                        ? 'bg-primary'
                                        : 'bg-green-400 bg-opacity-20 group') +
                                    ' w-full py-4 px-6 mx-1 transition-all hover:bg-primary cursor-pointer'
                                }
                                onClick={() => {
                                    navigate(item.path);
                                    setSelectedNavItem(index);
                                }}
                            >
                                <span className="material-icons text-3xl group-hover:text-white transition-all">
                                    {item.iconName}
                                </span>
                            </div>
                        ))}
                    </div>
                    <div className="mx-4 flex">
                        <div
                            className={
                                'group w-full py-4 px-6 transition-all hover:bg-gray-500 cursor-pointer ' +
                                (selectedNavItem === navArray.length + 1
                                    ? 'bg-gray-800'
                                    : 'bg-transparent ')
                            }
                            onClick={() => {
                                navigate('/account');
                                setSelectedNavItem(navArray.length + 1);
                            }}
                        >
                            <span
                                className={
                                    'material-icons text-3xl group-hover:text-white transition-all ' +
                                    (selectedNavItem === navArray.length + 1
                                        ? 'text-white'
                                        : 'text-black')
                                }
                            >
                                account_circle
                            </span>
                        </div>
                        <div
                            className={
                                'group w-full py-4 px-6 transition-all hover:bg-gray-500 cursor-pointer ' +
                                (selectedNavItem === navArray.length + 2
                                    ? 'bg-gray-800'
                                    : 'bg-transparent')
                            }
                            onClick={() => {
                                navigate('/settings');
                                setSelectedNavItem(navArray.length + 2);
                            }}
                        >
                            <span
                                className={
                                    'material-icons text-3xl group-hover:text-white transition-all ' +
                                    (selectedNavItem === navArray.length + 2
                                        ? 'text-white'
                                        : 'text-black')
                                }
                            >
                                settings
                            </span>
                        </div>
                    </div>
                </div>
                <div className="h-full w-full bg-gray-100">
                    <CustomRoutes />
                </div>
            </MobileView>
        </>
    );
}

export default AppContainer;
