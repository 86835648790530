import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectToken } from '../../../lib/stores/userStore';
import dateFormat from 'dateformat';

function TicketDetail(props) {
    const data = props.data;

    const token = useSelector(selectToken);

    const [ticketData, setTicketData] = useState({});

    useEffect(() => {
        axios
            .get(
                process.env.REACT_APP_API_URL +
                    '/project/getTicketInfo/' +
                    data.workspaceID +
                    '/' +
                    data.projectID +
                    '/' +
                    data.ticketID,
                {
                    'headers': {
                        'content-type': 'application/json',
                        'auth-token': token,
                    },
                }
            )
            .then((res) => {
                if (res.status === 200) {
                    setTicketData(res.data);
                }
            });
    }, [data]);

    return (
        <div style={{ 'width': (window.innerWidth / 4) * 2 }}>
            <div className="flex justify-between items-start">
                <div className="font-light mb-2">
                    <h1 className="text-2xl font-semibold text-gray-800">
                        {ticketData.infoData?.name}
                    </h1>
                    <div>
                        {dateFormat(
                            new Date(
                                ticketData.infoData?.createdAt._seconds *
                                    1000 || 0
                            ),
                            'dd.mm.yyyy - HH:MM'
                        )}
                    </div>
                </div>
                <span
                    className="material-icons text-accent text-3xl flex items-center justify-center cursor-pointer active:opacity-70"
                    onClick={() => props.onClose()}
                >
                    close
                </span>
            </div>
            <div className="flex justify-between ">
                <div className="w-full">
                    <div className="w-4/5 text-lg py-2">
                        {ticketData.infoData?.desc !== ''
                            ? ticketData.infoData?.desc
                            : 'Ohne Beschreibung'}
                    </div>
                </div>
                <div className="w-1/5 pl-5 flex flex-col border-l cursor-pointer border-gray-200">
                    <div className="group flex bg-gray-200 mb-4 rounded-md overflow-hidden transition-all hover:bg-gray-300">
                        <div className="flex px-3 py-2 truncate">
                            <span className="material-icons pr-2">person</span>
                            {ticketData.infoData?.createdBy}
                        </div>
                    </div>
                    <div className="group flex bg-gray-200 mb-4 cursor-pointer rounded-md overflow-hidden transition-all hover:bg-gray-300">
                        <div className="flex py-2 pl-3 pr-2">
                            Status:
                            <div className="font-semibold ml-2">
                                {ticketData.infoData?.currentState === 'open'
                                    ? 'Offen'
                                    : 'Fehler'}
                            </div>
                        </div>
                        <div className="flex justify-center items-center px-2 border-l border-gray-300 cursor-pointer transition-all group-hover:border-gray-400 hover:bg-gray-500">
                            <span className="material-icons text-2xl">
                                keyboard_arrow_down
                            </span>
                        </div>
                    </div>
                    <div className="group flex bg-primary text-white mb-4 rounded-md overflow-hidden transition-all hover:bg-accentDark">
                        <div className="flex px-3 py-2">
                            <span className="material-icons pr-2">edit</span>
                            Bearbeiten
                        </div>
                    </div>
                </div>
            </div>
            <div className="flex"></div>
        </div>
    );
}

export default TicketDetail;
