import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    allStaff: [],
};

export const staffSlice = createSlice({
    name: 'staff',
    initialState,
    reducers: {
        setAllStaff: (state, action) => {
            if (action.payload) {
                state.allStaff = action.payload;
            }
        },
    },
});

export const { setAllStaff } = staffSlice.actions;

export const selectAllStaff = (state) => state.staff.allStaff;

export default staffSlice.reducer;
