import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import Modal from '../../../lib/Modal';
import {
    selectAllProjects,
    selectSelectedProject,
    setAllProjects,
    setSelectedProject,
} from '../../../lib/stores/projectStore';
import { selectToken } from '../../../lib/stores/userStore';
import { selectSelectedWorkspace } from '../../../lib/stores/workspaceStore';
import ProjectCreate from './projectCreate';

function ProjectDashboard() {
    const navigate = useNavigate();

    const allProjects = useSelector(selectAllProjects);
    const selectedWorkspace = useSelector(selectSelectedWorkspace);
    const selectedProject = useSelector(selectSelectedProject);
    const token = useSelector(selectToken);
    const dispatch = useDispatch();

    const [modalOpen, setModalOpen] = useState(false);
    const [modalScreen, setModalScreen] = useState('');
    const [modalData, setModalData] = useState({});

    const [allTasks, setAllTasks] = useState([]);

    useEffect(() => {
        if (selectedProject.length === 0) {
            dispatch(setSelectedProject(allProjects[0]));
        }
    }, [allProjects]);

    useEffect(() => {
        axios
            .get(
                process.env.REACT_APP_API_URL +
                    '/project/getAllProjects/' +
                    selectedWorkspace,
                {
                    'headers': {
                        'content-type': 'application/json',
                        'auth-token': token,
                    },
                }
            )
            .then((res) => {
                if (res.status === 200) {
                    dispatch(setAllProjects(res.data));
                }
            });
    }, [selectedWorkspace, modalOpen]);

    useEffect(() => {
        axios
            .get(
                process.env.REACT_APP_API_URL +
                    '/project/getAllTicketsOfProject/' +
                    selectedWorkspace +
                    '/' +
                    selectedProject.id,
                {
                    'headers': {
                        'content-type': 'application/json',
                        'auth-token': token,
                    },
                }
            )
            .then((res) => {
                if (res.status === 200) {
                    setAllTasks(res.data);
                }
            });
    }, [selectedProject, modalOpen]);

    function updateTimer(ticketID) {
        axios
            .patch(
                process.env.REACT_APP_API_URL +
                    '/project/ticket/toggleTimer/' +
                    selectedWorkspace +
                    '/' +
                    selectedProject.id +
                    '/' +
                    ticketID,
                {},
                {
                    'headers': {
                        'auth-token': token,
                    },
                }
            )
            .then((res) => {
                if (res.status === 200) {
                    setAllTasks(res.data);
                }
            });
    }

    function openModal(Screen, data = {}, e) {
        if (e && e.stopPropagation) e.stopPropagation();
        setModalOpen(true);
        setModalData(data);

        setModalScreen(Screen);
    }

    function setTaskFinished(taskID) {
        axios
            .patch(
                process.env.REACT_APP_API_URL +
                    '/project/ticket/setTicketFinished/' +
                    selectedWorkspace +
                    '/' +
                    selectedProject.id +
                    '/' +
                    taskID,
                {},

                {
                    'headers': {
                        'content-type': 'application/json',
                        'auth-token': token,
                    },
                }
            )
            .then((res) => {
                if (res.status === 200) {
                    setAllTasks(res.data);
                }
            })
            .catch((e) => console.log(e));
    }

    return (
        <div className="w-full h-full">
            <Modal
                element={modalScreen}
                shown={modalOpen}
                data={modalData}
                onClose={() => setModalOpen(!modalOpen)}
            />
            <div className="w-full flex justify-between">
                <div className="text-4xl text-gray-600">Projekte</div>
                <div className="flex">
                    <div
                        className="border border-accent text-accent px-3 py-3 rounded-xl cursor-pointer flex items-center hover:bg-accentDark hover:bg-opacity-5 active:opacity-60 mr-4"
                        onClick={() => openModal('createTicket')}
                    >
                        <span className="material-icons mr-3">add</span>Ticket
                        hinzufügen
                    </div>
                    <div
                        className="bg-accent text-white px-3 py-3 rounded-xl cursor-pointer flex items-center hover:bg-accentDark active:opacity-60"
                        onClick={() => openModal('createProject')}
                    >
                        <span className="material-icons mr-3">add</span>Projekt
                        erstellen
                    </div>
                </div>
            </div>
            <div className="w-full flex py-4 overflow-hidden">
                {allProjects.length != 0 ? (
                    allProjects.map(({ id, data }, index) =>
                        selectedProject.id === id ? (
                            <div
                                key={index}
                                className="bg-primary bg-opacity-90 text-white rounded-full px-4 py-2 min-w-max h-10 flex items-center mr-3 text-base cursor-pointer hover:bg-primary hover:bg-opacity-30"
                                onClick={() =>
                                    dispatch(setSelectedProject({ id, data }))
                                }
                            >
                                <span className="material-icons text-white mr-2">
                                    done
                                </span>

                                {data.name}
                            </div>
                        ) : (
                            <div
                                key={index}
                                className="bg-gray-300 rounded-full px-4 py-2 min-w-max h-10 flex items-center mr-3 text-base cursor-pointer  hover:bg-gray-200"
                                onClick={() =>
                                    dispatch(setSelectedProject({ id, data }))
                                }
                            >
                                {data.name}
                            </div>
                        )
                    )
                ) : (
                    <div className="bg-gray-300 rounded-full px-4 py-2 min-w-max h-10 flex items-center mr-3 text-base">
                        Noch kein Projekt erstellt
                    </div>
                )}
            </div>
            <div className="w-full rounded-xl bg-white shadow-xl mt-10 overflow-hidden">
                <div className="text-xl text-gray-800 font-bold pb-5 pt-6 px-12">
                    Tickets ({allTasks.length}):
                </div>
                {allProjects.length != 0 ? (
                    allTasks.length !== 0 ? (
                        allTasks.map(({ data, id }) => (
                            <div
                                key={id}
                                className="border-t border-gray-200 py-4 pl-12 pr-6 flex justify-between transition-all hover:bg-gray-100 cursor-pointer"
                                onClick={() =>
                                    openModal('ticketDetail', {
                                        workspaceID: selectedWorkspace,
                                        projectID: selectedProject.id,
                                        ticketID: id,
                                    })
                                }
                            >
                                <div className="flex flex-col justify-center ">
                                    <div className="font-normal text-lg">
                                        {data.name}
                                    </div>
                                    <div className="font-light">
                                        {data.desc}
                                    </div>
                                </div>

                                <div className="flex justify-center items-center">
                                    <span
                                        className="material-icons text-red-600 rounded-full h-10 w-10 flex justify-center items-center mr-1 cursor-pointer transition-all hover:bg-gray-200"
                                        onClick={(e) =>
                                            openModal(
                                                'deleteTicket',
                                                {
                                                    workspaceID:
                                                        selectedWorkspace,
                                                    projectID:
                                                        selectedProject.id,
                                                    ticketID: id,
                                                },
                                                e
                                            )
                                        }
                                    >
                                        delete
                                    </span>
                                    <span
                                        className="material-icons text-primary rounded-full h-10 w-10 flex justify-center items-center mr-4 cursor-pointer transition-all hover:bg-gray-200"
                                        onClick={(e) =>
                                            openModal(
                                                'editTicket',
                                                {
                                                    workspaceID:
                                                        selectedWorkspace,
                                                    projectID:
                                                        selectedProject.id,
                                                    ticketID: id,
                                                },
                                                e
                                            )
                                        }
                                    >
                                        edit
                                    </span>
                                    <span
                                        className="material-icons h-10 w-10 mr-4 flex justify-center items-center cursor-pointer text-gray-400 border border-gray-400 rounded-full"
                                        onClick={(e) => {
                                            if (e && e.stopPropagation)
                                                e.stopPropagation();
                                            setTaskFinished(id);
                                        }}
                                    >
                                        check
                                    </span>
                                    <span
                                        className={
                                            'material-icons rounded-full h-10 w-10 flex justify-center items-center cursor-pointer transition-all ' +
                                            (data.running
                                                ? 'text-accent border-2 border-accent hover:bg-accent hover:bg-opacity-10'
                                                : 'text-white bg-accent hover:bg-accentDark')
                                        }
                                        onClick={(e) => {
                                            if (e && e.stopPropagation)
                                                e.stopPropagation();
                                            updateTimer(id);
                                        }}
                                    >
                                        {data.running ? 'stop' : 'play_arrow'}
                                    </span>
                                </div>
                            </div>
                        ))
                    ) : (
                        <div className="border-t border-gray-200 py-4 px-12 flex justify-between">
                            <div className="flex justify-center items-center">
                                <div
                                    className="border border-accent text-accent px-1 py-1 rounded-full cursor-pointer flex items-center hover:bg-accentDark hover:bg-opacity-5 active:opacity-60 mr-4"
                                    onClick={() => openModal('createTicket')}
                                >
                                    <span className="material-icons">add</span>
                                </div>
                                <div className="font-normal text-lg mr-4">
                                    Es wurde noch kein Ticket hinzugefügt
                                </div>
                            </div>
                        </div>
                    )
                ) : (
                    <div className="border-t border-gray-200 py-4 px-12 flex justify-between">
                        <div className="flex justify-center items-center">
                            <div
                                className="border border-accent text-accent px-1 py-1 rounded-full cursor-pointer flex items-center hover:bg-accentDark hover:bg-opacity-5 active:opacity-60 mr-4"
                                onClick={() => openModal('createProject')}
                            >
                                <span className="material-icons">add</span>
                            </div>
                            <div className="font-normal text-lg mr-4">
                                Es wurde noch kein Projekt erstellt
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
}

export default ProjectDashboard;
