import React from 'react';

function Cards(props) {
    return (
        <div className="w-full h-full">
            <div className="w-full flex justify-between">
                <div className="text-4xl text-gray-600">Chips</div>
                <div className="flex">
                    <div
                        className="bg-accent text-white px-3 py-3 rounded-xl cursor-pointer flex items-center hover:bg-accentDark active:opacity-60"
                        onClick={() => {}}
                    >
                        <span className="material-icons mr-3">add</span>Chip
                        hinzufügen
                    </div>
                </div>
            </div>
            <div className="w-full rounded-xl bg-white shadow-xl mt-10 overflow-hidden">
                <div className="text-xl text-gray-800 font-bold pb-5 pt-6 px-12">
                    Chips (2):
                </div>
            </div>
        </div>
    );
}

export default Cards;
