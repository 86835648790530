import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import Modal from '../../../lib/Modal';
import { selectToken } from '../../../lib/stores/userStore';
import { selectSelectedWorkspace } from '../../../lib/stores/workspaceStore';

function CustomerOverview() {
    const [allCustomers, setAllCustomer] = useState([]);

    const navigation = useNavigate();

    const [modalOpen, setModalOpen] = useState(false);
    const [modalData, setModalData] = useState({});
    const [modalScreen, setModalScreen] = useState('');

    const workspaceID = useSelector(selectSelectedWorkspace);
    const token = useSelector(selectToken);

    useEffect(() => {
        axios
            .get(
                process.env.REACT_APP_API_URL +
                    '/customer/getAllCustomers/' +
                    workspaceID,

                {
                    'headers': {
                        'content-type': 'application/json',
                        'auth-token': token,
                    },
                }
            )
            .then((res) => {
                if (res.status === 200) {
                    setAllCustomer(res.data);
                }
            });
    }, [token, workspaceID, modalOpen]);

    function openModal(Screen, data = {}) {
        setModalOpen(true);
        setModalData(data);

        setModalScreen(Screen);
    }

    return (
        <div className="w-full h-full">
            <Modal
                element={modalScreen}
                shown={modalOpen}
                data={modalData}
                onClose={() => setModalOpen(!modalOpen)}
            />
            <div className="w-full flex justify-between">
                <div className="text-4xl text-gray-600">Kundenübersicht</div>
                <div className="flex">
                    <div
                        className="bg-accent text-white px-3 py-3 rounded-xl cursor-pointer flex items-center hover:bg-accentDark active:opacity-60"
                        onClick={() => openModal('addCustomer', {})}
                    >
                        <span className="material-icons ">add</span>
                        <div className="hidden ml-3 md:block">
                            Kunden anlegen
                        </div>
                    </div>
                </div>
            </div>
            <div className="max-w-full flex py-4 "></div>
            <div className="w-full rounded-xl bg-white shadow-xl mt-10 overflow-hidden">
                <div className="text-xl text-gray-800 font-bold pb-5 pt-6 px-12">
                    Kunden
                </div>
                {allCustomers.length !== 0 ? (
                    allCustomers.map(({ data, id }, index) => (
                        <div
                            key={index}
                            className="border-t border-gray-200 py-4 pl-12 pr-6 flex justify-between transition-all hover:bg-gray-100 cursor-pointer"
                            onClick={() => {
                                navigation('' + id);
                            }}
                        >
                            <div className="flex justify-start items-center">
                                <div className="rounded-full bg-gray-400 mr-4">
                                    <span className="material-icons text-white m-2">
                                        {data.type === 'company'
                                            ? 'business'
                                            : 'person'}
                                    </span>
                                </div>
                                <div className="flex flex-col justify-center ">
                                    <div className="font-normal text-lg">
                                        {data.companyName}
                                    </div>
                                    <div className="font-thin line-clamp-1">
                                        {data.desc}
                                    </div>
                                </div>
                            </div>

                            <div className="flex justify-center items-center pl-5">
                                {data.labels ? (
                                    data.labels.map((label, index) => (
                                        <div
                                            className="py-2 px-3 text-sm rounded-xl mr-2"
                                            style={{ background: label.color }}
                                        >
                                            {label.text}
                                        </div>
                                    ))
                                ) : (
                                    <></>
                                )}
                                <span className="material-icons h-10 w-10 flex justify-center items-center cursor-pointer text-gray-400">
                                    chevron_right
                                </span>
                            </div>
                        </div>
                    ))
                ) : (
                    <div className="border-t border-gray-200 py-4 px-12 flex justify-between">
                        <div className="flex justify-center items-center">
                            <div
                                className="border border-accent text-accent px-1 py-1 rounded-full cursor-pointer flex items-center hover:bg-accentDark hover:bg-opacity-5 active:opacity-60 mr-4"
                                onClick={() => {}}
                            >
                                <span className="material-icons">add</span>
                            </div>
                            <div className="font-normal text-lg mr-4">
                                Es wurde noch kein Kunde angelegt
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
}

export default CustomerOverview;
