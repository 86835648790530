import React from 'react';
import { useNavigate } from 'react-router-dom';

function NotFound(props) {
    const navigation = useNavigate();

    return (
        <div className="flex flex-col items-center justify-center">
            <div className=" text-4xl font-bold mb-6 text-gray-700">404</div>
            <div
                className={
                    'bg-accent text-white px-3 py-3 rounded-xl cursor-pointer flex items-center hover:bg-accentDark active:opacity-60'
                }
                onClick={() => navigation('/', { replace: true })}
            >
                <span className="material-icons mr-3">home</span>
                Zur Startseite
            </div>
        </div>
    );
}

export default NotFound;
